import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';
import { Box, Text, Card, Flex, Button } from 'theme-ui';
import { Layout, Stack, Main } from '../layout';
import PageTitle from '../components/PageTitle';
import Divider from '../components/Divider';
import Section from '../components/Section';
import Seo from '../widgets/Seo';

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
};

const About = props => {
  const data = useStaticQuery(aboutQuery);
  const image = getImage(data.avatar);

  return (
    <Layout {...props}>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle header='About Me' subheader='' />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img image={image} />
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='My Story'>
                <Text variant='p'>
                  Machine Vision 분야에서 약 10년간 근무 경험이 있으며 현재는
                  AMHS 분야에서 개발 업무에 종사 하고 있습니다.
                </Text>
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title='Machine Vision'></Section>
              <Section title='FullStack'></Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  );
};

const aboutQuery = graphql`
  query AboutQuery {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1140
          height: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`;

export default About;
